<template>
    <div class="reset-pin">
        <Header />
        <b-container class="reset-pin-container">
            <b-row class="justify-content-center">
                <div>
                    <b-card-group deck>
                        <b-card>
                            <h1>Reset PIN</h1>
                            <template>
                                <div>
                                    <form @submit.prevent="handleSubmit">
                                        <h3 class="reset-pin-label">Email</h3>
                                        <vs-input id="email" type="email" class="mb-2 mr-sm-2 mb-sm-0" v-model="email"
                                            required />
                                        <h3 class="reset-pin-label">Pin Baru</h3>
                                        <div class="my-2">
                                            <small><i>*pin berjumlah harus 4 karakter</i>
                                            </small>
                                        </div>
                                        <div class="row">
                                            <div class="col-9">
                                                <vs-input v-show="!showPass" id="pin" type="password"
                                                    class="mb-2 mr-sm-2 mb-sm-0" v-model="pin" required />
                                                <vs-input v-show="showPass" id="pin" type="text"
                                                    class="mb-2 mr-sm-2 mb-sm-0" v-model="pin" required />
                                            </div>
                                            <div class="col-3">
                                                <b-button @click="showPass = !showPass" block class="btn-show-pin py-2">
                                                    <div v-show="!showPass">
                                                        <b-icon icon="eye"></b-icon>
                                                    </div>
                                                    <div v-show="showPass">
                                                        <b-icon icon="eye-slash"></b-icon>
                                                    </div>
                                                </b-button>
                                            </div>
                                        </div>
                                        <div class="error my-2" v-if="!$v.pin.minLength">
                                            <p>
                                                PIN minimal
                                                {{ $v.pin.$params.minLength.min }} karakter.
                                            </p>
                                        </div>

                                        <h3 class="reset-pin-label">Konfirmasi PIN</h3>
                                        <div class="row">
                                            <div class="col-9">
                                                <vs-input v-show="!showConfirmationPass" id="pin_confirmation"
                                                    type="password" class="mb-2 mr-sm-2 mb-sm-0"
                                                    v-model="pin_confirmation" required />
                                                <vs-input v-show="showConfirmationPass" id="pin_confirmation"
                                                    type="text" class="mb-2 mr-sm-2 mb-sm-0" v-model="pin_confirmation"
                                                    required />
                                            </div>
                                            <div class="col-3">
                                                <b-button @click="showConfirmationPass = !showConfirmationPass" block
                                                    class="btn-show-pin py-2">
                                                    <div v-show="!showConfirmationPass">
                                                        <b-icon icon="eye"></b-icon>
                                                    </div>
                                                    <div v-show="showConfirmationPass">
                                                        <b-icon icon="eye-slash"></b-icon>
                                                    </div>
                                                </b-button>
                                            </div>
                                        </div>

                                        <div class="error my-2" v-if="!$v.pin_confirmation.sameAsPin">
                                            <p>PIN yang Anda masukkan tidak sama.</p>
                                        </div>
                                        <div class="mb-4"></div>

                                        <!-- <div class="my-2" v-if="failedResetPin">
                        <p>
                          Email dan PIN tidak sesuai <br />
                          Silahkan cek kembali email dan PIN Anda
                        </p>
                      </div> -->

                                        <div class="pt-2">
                                            <b-button block :disabled="loading" type="submit" @click="handleSubmit()"
                                                class="btn-tertiary py-2">
                                                <div v-if="loading">
                                                    <div class="spinner-border spinner-border-sm"></div>
                                                    Loading
                                                </div>
                                                <div v-else>Submit</div>
                                            </b-button>
                                        </div>
                                    </form>
                                </div>
                            </template>
                        </b-card>
                    </b-card-group>
                </div>
            </b-row>

            <div class="modal-reset-pin">
                <b-modal id="modal-success-reset-pin" v-model="successResetPin" size="lg"
                    class="modal-success-reset-pin" centered hide-footer>
                    <div class="d-block text-center">
                        <img src="@/assets/img/ekyc/verification-proccess.svg" alt="" />
                        <h1 class="p-4 my-2 text-dark">
                            Permohonan Reset PIN Berhasil
                        </h1>
                        <p class="text-dark">
                            Silahkan lakukan login<br />dengan PIN baru Anda.
                        </p>
                    </div>
                </b-modal>
            </div>

            <div class="modal-reset-pin">
                <b-modal id="modal-success-reset-pin" v-model="failedResetPin" size="lg" class="modal-success-reset-pin"
                    centered hide-footer no-close-on-backdrop>
                    <div class="d-block text-center">
                        <img src="@/assets/img/ekyc/failed.svg" alt="" />
                        <h1 class="p-4 my-2 text-dark">
                            Ups! <br />
                            Reset PIN Gagal
                        </h1>
                        <p class="text-dark">
                            Ada kesalahan saat reset PIN.
                            <!-- <br />Mohon hubungi customer
                            Care. -->
                        </p>
                        <!-- <b-row class="justify-content-center">
                            <b-button href="#" block class="mt-3 mx-2 btn-tertiary py-2">Hubungi customer
                                Care</b-button>
                        </b-row> -->
                    </div>
                </b-modal>
            </div>
        </b-container>
        <Footer4 />
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import Header from "@/components/partials/header/Header.vue";
import Footer4 from "@/components/partials/footer/Footer4.vue";
import { sameAs, minLength } from "vuelidate/lib/validators";

import axios from "axios";

let token = new URL(location.href).searchParams.get("token");
let email = new URL(location.href).searchParams.get("email");

const instance = axios.create({
    withCredentials: true,
});

export default {
    name: "ResetPin",
    metaInfo: {
        title: "Reset PIN | CGS iTrade Fund",
    },
    components: {
        Header,
        Footer4,
    },
    data() {
        return {
            successResetPin: false,
            failedResetPin: false,
            token: token,
            email: email,
            pin: "",
            pin_confirmation: "",
            loading: false,
            processing: false,
            showPass: false,
            showConfirmationPass: false,
        };
    },
    computed: {
        ...mapGetters({
            app_url: "app_url",
        }),
    },
    validations: {
        pin: {
            minLength: minLength(4),
            maxLength: minLength(4),
        },
        pin_confirmation: {
            sameAsPin: sameAs("pin"),
        },
    },
    methods: {
        handleSubmit() {
            if (!this.processing) {
                this.processing = true;
                let data = new FormData();
                data.append("email", this.email);
                data.append("new_pin", this.pin);
                data.append("token", this.token);

                let axiosConfig = {
                    headers: {
                        "Content-Type": "application/json",
                    },
                };

                instance
                    .post(`${this.app_url}reset-pin`, data, axiosConfig)
                    .then((res) => {
                        if (res.data.meta.code == 255) {
                            this.failedResetPin = true;
                            this.processing = false;
                        } else {
                            this.successResetPin = true;

                            this.loading = !false;
                            window.setTimeout(function () {
                                window.location.href = "/";
                            }, 3000);
                            this.processing = false;
                        }
                    })
                    .catch(() => {
                        this.failedResetPin = true;
                        this.processing = false;
                    });
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.reset-pin {
    background: #f3f4f6;
}

.reset-pin-container {
    padding-top: 140px;
    padding-bottom: 140px;
}

h1 {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;

    color: #111827;
}

h3 {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;

    color: #4b5563;
}

p {
    font-family: $inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;

    color: #e53e3e;
}

span {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;

    color: #4f566b;
}

.error {
    p {
        font-family: $inter;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        color: #e53e3e;
    }
}

.vs-con-input-label {
    width: 100%;
}

.reset-pin-label {
    padding-top: 16px;
}

.reset-pin-link {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;

    color: #6b7280;
    text-decoration: none;
}

.custom-control-label {
    font-family: $inter;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;

    color: #3c4257;
}

.custom-control-label:before {
    background-color: #e60012 !important;
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::before {
    background-color: #e60012 !important;
}

.card-body {
    padding: 48px;
    box-shadow: 0px 10px 15px rgba(107, 114, 128, 0.1),
        0px 4px 6px rgba(107, 114, 128, 0.05);
    border-radius: 5px;
    width: 500px;
}

.form-control:focus {
    outline: none !important;
    border: 1px solid !important;
    box-shadow: none !important;
}

@media only screen and (min-width: 1366px) {
    .reset-pin-container {
        padding-bottom: 300px;
    }
}

@media only screen and (max-width: 768px) {
    .reset-pin-container {
        padding-bottom: 60px;
    }

    .back-nav {
        padding: 0px 240px 20px 0px;
    }

    .card-body {
        width: 100%;
    }
}
</style>